<script>
import MarcarComoRegalado from "./MarcarComoRegalado";

export default {
  mixins: [ MarcarComoRegalado ],
  props: {
    list: {
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  }
};
</script>