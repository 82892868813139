<template>
  <span v-if="show">
    <div
      class="b-card-copy b-card-copy--flat"
      @click="copy"
      :class="copied ? 'b-card-copy--active' : ''"
    >
      <address v-text="address" />
      <div
        class="b-card-copy__copy"
        v-tooltip="tooltipObject"
        :class="copied ? 'b-card-copy__copy--active' : ''"
      >
        <i class="uil uil-copy-alt"></i>
      </div>
    </div>
  </span>
</template>
<script>

import 'floating-vue/dist/style.css'
import { VTooltip } from 'floating-vue'
export default {
  name: "ListOwnerAddress",
  directives: {
    "tooltip": VTooltip
  },
  props: {
    list: {
      required: true,
    },
  },
  data() {
    return {
      copied: false,
      showTooltip: false,
    };
  },
  computed: {
    show() {
      return !(
        this.list.address === "" ||
        this.list.address === null ||
        this.list.address === undefined
      );
    },
    tooltipObject() {
      return {
        content: this.tooltip,
        shown: this.showTooltip,
        showTriggers: ["hover"],
        hideTriggers: this.showTooltip ? [] : ["hover"],
      };
    },
    tooltip() {
      return this.copied ? this.$t("guest.copied") : this.$t("guest.copy");
    },
    address() {
      return this.list.address;
    },
  },
  methods: {
    copy() {
      //vue-clipboard2
      this.$copyText(this.address).then(
        () => {
          this.copied = true;
          this.showTooltip = true;
          this.$sleep(() => (this.showTooltip = false), 2000);
        },
        function (e) {
          console.log(e);
          this.copied = false;
        }
      );
    },
  },
};
</script>
