<template>
  <div class="card-success">
    <div class="card-success__ico">
      <img
        src="../../../assets/img/ico/ico-color-heart-lock.svg"
        alt="Regalo reservado"
      />
    </div>
    <h3 class="card-success__header">{{ $t("guest.reservation.successTitle") }}</h3>
    <div class="card-success__content" v-html="rememberText(days)"></div>
    <div class="card-success__actions">
      <button
        @click="$emit('next')"
        class="button button--primary button--w60"
        >{{ $t("guest.reservation.successButton") }}</button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "FluxRegaloReservaStep2",
  computed: {
    days() {
      return '3'
    }
  },
  methods: {
    rememberText(days) {
      return this.$t("guest.reservation.successMessage", { days });
    }
  }
};
</script>
