<template>
  <div class="flux-regalo">
    <div class="flux-regalo__title">{{ $t("guest.reserve") }}</div>

    <div class="flux-regalo__msg">{{ $t("guest.reservation.goingTo") }}</div>

    <div class="flux-regalo__product">
      <div class="card-mini-product">
        <div class="card-mini-product__picture">
          <img :src="$getImgUrl(product.photo)" alt="Foto del catálogo" />
        </div>

        <div class="card-mini-product__name" v-tooltip="product.name">
          {{ $utils.truncate(product.name, 60) }}
        </div>
      </div>
    </div>

    <div class="flux-regalo__info">
      <i class="uil uil-info-circle"></i>
      <span v-html="rememberText(days)"></span>
    </div>

    <!-- <div class="flux-regalo__msg flux-regalo__msg--small no-flex">
      Recuerda que <strong>tienes {{ days }} días para comprarlo</strong>, si no, tu
      reserva se eliminará.
    </div> -->

    <div class="flux-regalo__form">
      <div class="bb-form-item">
        <div class="bb-form-label">{{ $t("signup.yourName") }}*</div>
        <input
          type="text"
          class="bb-form"
          :class="{ 'bb-form--success': nameIsValid }"
          :placeholder="$t('guest.reservation.namePlaceholder')"
          v-model="name"
          required
        />
      </div>
      <div class="bb-form-item">
        <div class="bb-form-label">{{ $t("login.yourEmail") }}*</div>
        <input
          type="email"
          class="bb-form"
          :class="{
            'bb-form--success': emailIsValid,
            'bb-form--danger': emailHasError,
          }"
          :placeholder="$t('guest.reservation.emailPlaceholder')"
          v-model.lazy="email"
          required
        />
        <div class="bb-form-error" v-show="emailHasError">
          {{ $t("guest.reservation.errorField") }}
        </div>
      </div>
      <div class="bb-form-item bb-form-item--inline">
        <label for="aceptance" class="bb-form-checkbox">
          <input
            type="checkbox"
            id="aceptance"
            placeholder="Escribe aquí tu email"
            v-model="terms"
            true-value="yes"
            false-value="no"
            required
          />
          <span v-html="getTermsText()"
            ></span
          >
        </label>
      </div>
      <div class="mar-t-2">
        <loading-button v-if="loadingBooking" />
        <button
          v-else
          class="button button--primary button--block"
          @click="book"
          :disabled="disabled"
        >
          {{ $t("guest.reserve") }}
        </button>
      </div>
    </div>

    <!-- <div class="flux-regalo__actions"></div> -->
  </div>
</template>
<script>
import LoadingButton from "../../../components/LoadingButton";
import "floating-vue/dist/style.css";
import { VTooltip } from "floating-vue";

export default {
  name: "FluxRegaloReservaStep1",
  components: {
    "loading-button": LoadingButton,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    list: {
      required: true,
    },
  },
  data() {
    return {
      loadingBooking: false,
      name: "",
      email: "",
      terms: false,
    };
  },
  computed: {
    nameIsValid() {
      return this.name !== "";
    },
    emailIsValid() {
      if (this.email === "") return false;
      return this.$utils.validateEmail(this.email);
    },
    emailHasError() {
      return !!(this.email !== "" && !this.$utils.validateEmail(this.email));
    },
    days() {
      return "3";
    },
    disabled() {
      return !(
        this.terms === "yes" &&
        this.name !== "" &&
        this.$utils.validateEmail(this.email)
      );
    },
    productRetailersName() {
      return this.product.productRetailers.map(function (item) {
        return item["name"];
      });
    },
    SoldByHelloBB() {
      if (this.productRetailersName.includes("HelloBB")) {
        return "Active";
      }
      return "No";
    },
  },
  methods: {
    async bookFreeProduct() {
      this.loadingBooking = true;
      await this.$store.dispatch("bookFreeProduct", {
        list: this.list,
        product: this.product,
        email: this.email,
        name: this.name,
      });
      this.loadingBooking = false;
    },
    async bookProduct() {
      this.loadingBooking = true;
      await this.$store.dispatch("bookProduct", {
        list: this.list,
        product: this.product,
        email: this.email,
        name: this.name,
      });
      this.loadingBooking = false;
    },
    async book() {
      this.product.isFree ? await this.bookFreeProduct() : await this.bookProduct();

      window.mixpanel.identify(this.list.userId);
      window.mixpanel.track("Reservar", {
        "Object name": this.product.name,
        "Object URL": this.product.URL,
        "Reserver name": this.name,
        "Item retailers": this.productRetailersName,
        "Sold by HelloBB": this.SoldByHelloBB,
      });

      this.$emit("next");
    },
    rememberText(days) {
      return this.$t("guest.reservation.rememberThat", { days: days });
    },
    getTermsText() {
      return this.$t("guest.reservation.terms");
    },
  },
};
</script>
