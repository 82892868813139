<script>
export default {
  data() {
    return {
      loadingMarcarComoRegalado : false
    }
  },
  methods: {
    async marcarComoRegalado() {
      this.loadingMarcarComoRegalado = true
      document.body.classList.remove("modal-open");
      try {
        await this.$store.dispatch('updateProductStatusForGuest',{list: this.list, product: this.product, status: this.REGALADO})

        window.mixpanel.identify(this.list.userId);
        window.mixpanel.track("Marca regalat", {
          "Gift name": this.product.name,
          "Gift retailer": this.product.retailerName,
          "Gift retailers": this.product.isFree ? "" : this.product.productRetailers?.map((retailer) => retailer.name),
          "Gift URL": this.product.isFree ? this.product.originalUrl : "https://www.hellobb.net/p/" + this.product.id,
          "Role": "regalador",
          "Gift amount": this.product.price,
          "Gift added date": this.product.addedAt,
          "Product ID": !this.product.isFree ? this.product.id : 0,
        });
        this.loadingMarcarComoRegalado = false
        await this.$router.push({
          name: "enCamino",
          params: { 
            id: this.list.id,
            productId: this.product.isFree ? this.product.id : this.product.listProductId, 
            productType: this.product.isFree ? 'free' : 'catalogue' },
        });
      } catch (error) {
        console.log(error);
        this.loadingMarcarComoRegalado = false;
        return;
      }
      this.loadingMarcarComoRegalado = false
    },
  },
  created() {
    this.REGALADO = 2;
  },
};
</script>