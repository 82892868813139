<template>
  <div class="bb-range">
    <div class="bb-range__range">
      <input
        type="range"
        :min="min"
        :max="max"
        v-model="inputValue"
        class="bb-range__input"
        @input="$emit('input', inputValue2)"
      />
      <!-- <output class="bb-range__tooltip" :style="{ left: rangePercentage + '% - 25px' }">
        <span>{{ inputValue }} € </span>
      </output> -->

      <!-- <output
        class="bb-range__tooltip bb-range__tooltip--right"
        :style="{ left: rangePercentage + '%' }"
      > -->

      <!-- <output class="bb-range__tooltip bb-range__tooltip--right">
        <span>{{ inputValue }} € </span>
      </output> -->
    </div>
    <div class="bb-range__shorcuts" v-if="showButtons">
      <button
        v-for="(item, index) in shortcuts"
        :key="index"
        @click="sendValue(item)"
        class="button button--outline-dark button--sm"
      >
        {{ item }} €
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showButtons: {
      default: false,
    },
    min: {
      default: 1,
    },
    max: {
      default: 100,
    },
    productPrice: {
      default: 0,
    },
    initialInputValue: {
      default: 0,
    },
    offset: "10px",
  },
  data() {
    return {
      inputValue: 1,
    };
  },
  // computed: {
  //   rangePercentage() {
  //     // return ((this.inputValue - this.min) / (this.max - this.min)) * 100;
  //     return ((this.inputValue - this.min) * 100) / (this.max - this.min);
  //   },
  // },
  methods: {
    sendValue(value) {
      this.inputValue = value;
      this.$emit("input", value);
    },
  },

  computed: {
    inputValue2() {
      if (this.inputValue <= this.productPrice) return this.inputValue;
      return this.productPrice;
    },
    shortcuts() {
      const baseValue = this.productPrice;
      return [
        Math.round(baseValue * 0.25),
        Math.round(baseValue * 0.5),
        Math.round(baseValue * 0.75),
      ];
    },
  },
  watch: {
    initialInputValue(newValue) {
      this.inputValue = newValue;
    }
  },
  mounted() {
    this.inputValue = Math.max(this.initialInputValue, this.min);
  },
};
</script>

<style scoped></style>
