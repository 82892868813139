<template>
  <div class="flux-regalo">
    <div class="flux-regalo__title">{{ $t("guest.doYouPurchased") }}</div>

    <div class="flux-regalo__msg">{{ $t("guest.setAsPurchasedOnlyIf") }}</div>
    <div class="flux-regalo__msg flux-regalo__msg--small">
      <img src="../../../assets/img/ico/ico-question.svg" alt="" />
      {{ $t("guest.setAsPurchasedAdv", { name: myList.ownerName }) }}
    </div>

    <loading-button v-if="loadingMarcarComoRegalado" />
    <div v-else class="flux-regalo__actions">
      <a href="#" class="button button--primary" @click="marcarComoRegalado()"
        >{{ $t("guest.setAsPurchasedButton") }}</a
      >
    </div>
    <div class="info-compra" v-if="typeof myList.address === 'string' && myList.address.trim().length > 0">
      <div class="info-compra__title">{{ $t("guest.infoForPurchasing") }}</div>
      <div class="info-compra__cols">
        <div class="info-compra__col">
          <h3 class="info-compra__col-title">
            {{ $t("guest.addressOf", { name:myList.ownerName }) }}
          </h3>
          <div class="info-compra__col-content">
            <list-owner-address :list="list"></list-owner-address>
          </div>
        </div>
        <div class="info-compra__col"></div>
      </div>
    </div>
  </div>
</template>
<script>
import CompradoYa from "./mixins/CompradoYa";
import ListOwnerAddress from "./ListOwnerAddress";
import LoadingButton from "../../../components/LoadingButton";
import { mapGetters } from "vuex";

export default {
  name: "FluxRegaloStep2",
  mixins: [CompradoYa],
  components: {
    "list-owner-address": ListOwnerAddress,
    "loading-button": LoadingButton,
  },
  props: {
    retailer: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
  },
};
</script>
