<template>
  <div class="flux-regalo">
    <div class="flux-regalo__title">{{ $t("guest.goToRetailerModalTitle") }}</div>
    <div class="flux-regalo__steps">
      <div class="flux-regalo__step">
        <div class="flux-regalo__number">
          <span class="flux-regalo__circle flux-regalo__circle--1">1</span>
        </div>
        <div class="flux-regalo__step-content">
          <div v-if="retailerIsNotHelloBB" class="flux-regalo__name">
            {{ $t("guest.goToRetailerModalStep1", { retailerName: retailerName }) }}
          </div>
          <div v-else class="flux-regalo__name">
            {{ $t("guest.goToRetailerModalStep1NoRetailer") }}
          </div>
        </div>
      </div>
      <div class="flux-regalo__step">
        <div class="flux-regalo__number">
          <span class="flux-regalo__circle flux-regalo__circle--2">2</span>
        </div>
        <div class="flux-regalo__step-content">
          <div class="flux-regalo__name">{{ $t("guest.goToRetailerModalStep2") }}</div>
          <div v-if="retailerIsNotHelloBB" class="flux-regalo__description">
            {{ $t("guest.goToRetailerModalStep2TextNoHelloBB", { name: myList.ownerName }) }}
          </div>
          <div v-else class="flux-regalo__description">
            {{ $t("guest.goToRetailerModalStep2TextHelloBB", { name: myList.ownerName }) }}
          </div>
        </div>
      </div>
    </div>
    <div class="flux-regalo__informacion">
      <div
        class="info-compra"
        v-if="typeof myList.address === 'string' && myList.address.trim().length > 0"
      >
        <div class="info-compra__title">{{ $t("guest.infoForPurchasing") }}</div>
        <div class="info-compra__cols">
          <div class="info-compra__col">
            <h3 class="info-compra__col-title">{{ $t("guest.addressOf", { name: myList.ownerName }) }}</h3>
            <div class="info-compra__col-content">
              <list-owner-address :list="list"></list-owner-address>
            </div>
          </div>
          <div class="info-compra__col"></div>
        </div>
      </div>
    </div>
    <div class="flux-regalo__actions flux-regalo__actions--column" id="comprar">
      <a
        v-if="retailerIsNotHelloBB"
        :href="retailerLink"
        @click="$emit('next')"
        target="_blank"
        class="button button--primary"
        >{{ $t("guest.goToRetailerModalButton", { retailerName: retailerName }) }}</a
      >
      <a
        href="#"
        @click="$emit('next')"
        v-else
        id="product-component-1654766974088"
        v-show="!retailerIsNotHelloBB"
        style="margin: auto"
      ></a>
      <span class="flux-regalo__disclaimer" v-if="retailerName == 'Amazon'">
        {{ $t("guest.amazonInfoDesclaimer") }}
      </span>
    </div>
  </div>
</template>
<script>
import ListOwnerAddress from "./ListOwnerAddress";
import { mapGetters } from "vuex";

export default {
  name: "FluxRegaloStep1",
  components: {
    "list-owner-address": ListOwnerAddress,
  },
  props: {
    list: {
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    retailer: {
      type: Object,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copy() {
      //vue-clipboard2
      this.$copyText(this.address).then(
        () => {
          this.copied = true;
        },
        function (e) {
          console.log(e);
          this.copied = false;
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
    retailerLink() {
      return this.product.isFree ? this.product.url : this.retailer?.url;
    },
    retailerName() {
      return this.retailer?.name;
    },
    address() {
      return this.list.address;
    },
    retailerIsNotHelloBB() {
      //return false;
      return this.retailer?.name != "HelloBB";
    },
  },
};
</script>
